import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CreateVoucherPage from "../CreateVoucher/create_voucher_page";
import CreateLoyaltyPage from "../CreateLoyalty/createloyalty_page";
import ManageProgram from "../ManageProgram/manage_program";
import ManageVoucher from "../ManageVoucher/manage_voucher";
import CreateLoyaltyMain from "../CreateLoyaltyMain/create_loyalty_main";
import Analytics from "../Analytics/analytics";
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
import * as selector from "../../selectors/selectors";
import * as constants from "../../utils/constants";
import useGetVerified from "../../Hooks/getVerified";
import {
  warningWithTimeout,
  extractCurrencyCode,
  extractDiscountVoucherFromValueDescription,
} from "../../utils/helpers";
import { VoucherType } from "../../utils/constants";
import Dashboard from "../../Components/Layouts/Dashboard/dashboard";
import UserManagement from "../UserManagement/user-management";
import Looker from "../Looker/looker";
import CreateMilestoneStampPage from "../CreateMilestoneStamp/create_milestone_stamp_page";

function Main_Page() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getVerified = useGetVerified();

  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isLoggedOut = useSelector(selectors.getIsLoggedOut);

  // Drop Down
  const [programSelected, setProgramSelected] = useState(null);
  const program = useSelector(selector.getProgram);
  const industries = useSelector(selector.getIndustries);
  const selectedLp = useSelector(selectors.getSelectedLp);

  useEffect(() => {
    if (!isAuthenticated && isLoggedOut) {
      getVerified();
    }
  }, [getVerified, isAuthenticated, isLoggedOut, navigate]);

  useEffect(() => {
    if (selectedLp && program?.length && program?.length > 0) {
      console.log("useEffect LP", selectedLp);
      const selectedProgram = program?.find(
        (program) => program.id === selectedLp,
      );
      if (selectedProgram) {
        setProgramSelected(selectedProgram);
      }
    } else {
      setProgramSelected(null); // Or set to a default program if needed
    }
  }, [program, selectedLp]);

  let programSelected_industry;

  if (industries && industries.length > 0) {
    programSelected_industry = industries.find(
      (ind) => ind.id === (programSelected?.productTypeId ?? 0),
    );
  }

  const params = useParams();
  const [display, setDisplay] = useState(constants.OVERVIEW_PAGE);
  const [id, setId] = useState(/** @type {string | undefined} */ (undefined));

  const [voucherData, setVoucherData] = useState(null);
  const [voucherFormMode, setVoucherFormMode] = useState(
    /** @type {"edit"|"restart"|"create"|"blocked"} */ ("create"),
  );

  useEffect(() => {
    setDisplay(params.page || constants.OVERVIEW_PAGE);
  }, [params.page]);

  useEffect(() => {
    setId(params.id);
  }, [params.id]);

  /**
   *
   * @param {import("../../types").Business} data
   * @returns {void}
   */
  const handleEdit = (data) => {
    navigate(`/dashboard/${constants.CREATE_LOYALTY_PAGE}/${data.id}`);
  };

  /**
   *
   * @param {import("../../types").Voucher} voucher_data
   * @param {"edit"|"restart"} mode
   * @param {boolean} canRestart
   */
  const handleVoucherEdit = (voucher_data, mode, canRestart) => {
    console.log("handle Voucher Edit", voucher_data);
    setVoucherData(voucher_data);
    navigate(`/dashboard/${constants.CREATE_VOUCHER_PAGE}`);
    if (canRestart) {
      setVoucherFormMode(mode);
    } else {
      setVoucherFormMode("blocked");
    }

    // eslint-disable-next-line default-case
    switch (voucher_data.voucherTypeId) {
      case VoucherType.DISCOUNT:
        const discountVoucher = extractDiscountVoucherFromValueDescription(
          voucher_data.valueDescription,
        );
        dispatch(actions.setDiscountVoucher(discountVoucher));
        dispatch(actions.setGiveaway(voucher_data.productServiceDescription));
        break;
      case VoucherType.CASH:
        dispatch(actions.setCashVoucher(voucher_data.value));
        dispatch(actions.setGiveaway(voucher_data.productServiceDescription));
        const currencyCode = extractCurrencyCode(voucher_data.valueDescription);
        dispatch(actions.setCurrency(currencyCode));
        break;
      case VoucherType.GIVEAWAY:
        dispatch(actions.setGiveaway(voucher_data.productServiceDescription));
        dispatch(actions.setSpecialAddress(voucher_data.specialAddress));
        break;
      case VoucherType.EVENT_PASS:
        dispatch(actions.setEventPass(voucher_data.eventCode));
        dispatch(actions.setEventAddress(voucher_data.eventAddress));
        break;
    }
  };

  const handleCreateVoucher = useCallback(() => {
    if (selectedLp) {
      navigate(`/dashboard/${constants.CREATE_VOUCHER_PAGE}`);
      setVoucherData(null);
      setVoucherFormMode("create");
    } else {
      warningWithTimeout({
        message: "Please select the loyalty program first.",
      });
    }
  }, [selectedLp]);

  return (
    <Dashboard display={display}>
      {display === constants.CREATE_LOYALTY_PAGE && (
        <CreateLoyaltyPage
          id={id}
          onSave={(loyaltyProgram) => {
            // Populate state `data` with loyalty program data returned by onSave event
            handleEdit(loyaltyProgram);
          }}
          new_user_page={() => {
            navigate(`/dashboard/${constants.OVERVIEW_PAGE}`);
          }}
          handleSkipToOverview={() => {
            navigate(`/dashboard/${constants.OVERVIEW_PAGE}`);
          }}
          handleCreateVoucherCampaign={handleCreateVoucher}
        />
      )}
      {display === constants.CREATE_VOUCHER_PAGE && (
        <CreateVoucherPage
          data={voucherData}
          mode={voucherFormMode}
          manage_Page={() => {
            navigate(`/dashboard/${constants.MANAGE_VOUCHER_PAGE}`);
            setVoucherData(null);
            setVoucherFormMode("create");
          }}
        />
      )}
      {display === constants.LOOKER_PAGE && <Looker section={id} />}
      {display === constants.ANALYTICS_PAGE && <Analytics />}
      {display === constants.MANAGE_PROGRAM_PAGE && (
        <ManageProgram
          handleAdd={() => {
            navigate(`/dashboard/${constants.CREATE_LOYALTY_PAGE}`);
            setVoucherData(null);
          }}
          handleEdit={handleEdit}
        />
      )}
      {display === constants.MANAGE_VOUCHER_PAGE && (
        <ManageVoucher
          handleEdit={(voucher) => {
            handleVoucherEdit(voucher, "edit", true);
          }}
          handleRestart={(voucher, canRestart) => {
            handleVoucherEdit(
              {
                ...voucher,
                // Clear start date and expiry date
                startDate: undefined,
                expiryDate: undefined,
              },
              "restart",
              canRestart,
            );
          }}
          handleCreate={handleCreateVoucher}
        />
      )}

      {display === constants.OVERVIEW_PAGE && (
        <CreateLoyaltyMain handleClick={handleCreateVoucher} />
      )}

      {display === constants.USER_MANAGEMENT_PAGE && <UserManagement />}

      {display === constants.CREATE_MILESTONE_STAMP && (
        <CreateMilestoneStampPage />
      )}
    </Dashboard>
  );
}

export default Main_Page;
