import { isAxiosError } from "axios";
import { Voucher } from "../types";
import axiosInstance from "./axiosIntercept";
import { fetchProductServiceDescription } from "../services/voucherService";

export const getVoucherBank = async (programId: number): Promise<Voucher[]> => {
  try {
    const response = await axiosInstance.get<Voucher[]>(`/v1/voucher/bank`, {
      params: {
        programId,
      },
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    });

    // Fetch product service descriptions for each voucher
    const vouchersWithDesc = await Promise.all(
      response.data.map(async (voucher) => {
        if (voucher.productServiceRefId) {
          const productServiceDescription =
            await fetchProductServiceDescription(voucher.productServiceRefId);
          return {
            ...voucher,
            productServiceDescription: productServiceDescription || "",
          };
        }
        return voucher;
      }),
    );

    // console.log("response with descriptions", vouchersWithDesc);
    return vouchersWithDesc;
  } catch (error: any) {
    if (isAxiosError(error)) {
      console.error("API Error:", error.response?.status, error.response?.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};

export const getVoucherBankCount = async (
  programId: number,
): Promise<number> => {
  try {
    const response = await getVoucherBank(programId);
    // console.log("Bank vouchers:", response);
    return response.length;
  } catch (error) {
    console.error("Error fetching voucher bank count:", error);
    return 0;
  }
};
