import React, { useCallback } from "react";
import "./promo.css";
import { ReactComponent as EditDocIcon } from "../../assets/symbols/edit-doc.svg";
import { ReactComponent as RefreshIcon } from "../../assets/symbols/refresh.svg";
import AppButton from "../AppButton/app_button";
import Tooltip from "../Tooltip/tooltip";
import TooltipContext from "../TooltipContext/tooltip_context";
import classNames from "classnames";

/**
 *
 * @param {object} props
 * @param {(status: boolean) => void} props.onChangeActiveStatus
 * @param {import("react").ReactNode} props.children
 * @param {number} props.issued
 * @param {number} props.redeemed
 * @param {boolean} props.isEditable
 * @param {boolean} props.isExpired
 * @param {boolean} props.isActive
 * @param {string} props.isStarted
 * @param {boolean} props.isAutovoucher
 * @param {boolean} props.isReferralVoucher
 * @param {boolean} props.canRestart
 * @param {boolean} [props.voucherLimitExceeded]
 * @param {() => void} props.onEditClick
 * @param {() => void} props.onRestartClick
 * @param {import("react").MouseEventHandler} props.socialHandler
 * @returns {JSX.Element}
 */
function Promo({
  children,
  issued,
  redeemed,
  isEditable,
  isExpired,
  isActive,
  isStarted,
  isAutovoucher,
  isReferralVoucher,
  canRestart,
  voucherLimitExceeded,
  onChangeActiveStatus,
  onEditClick,
  onRestartClick,
  socialHandler,
}) {
  const currentDate = new Date();

  return (
    <div className="promo">
      {isAutovoucher && (
        <div className="promo__auto-voucher">
          <TooltipContext>
            <span className="visuallyhidden">Autovoucher</span>
            <Tooltip>Autovoucher</Tooltip>
          </TooltipContext>
        </div>
      )}
      <div className="promo__summary">{children}</div>
      <div className="promo__data">
        <div className="promo__stat">
          {new Date(isStarted) > currentDate ? (
            <div className="promo__param">
              Number of vouchers&nbsp;scheduled
            </div>
          ) : (
            <div className="promo__param">Number of vouchers&nbsp;issued </div>
          )}
          <div className="promo__value">{issued}</div>
        </div>
        <div className="promo__stat">
          <div className="promo__param">Number of vouchers&nbsp;used</div>
          <div className="promo__value">{redeemed}</div>
        </div>
      </div>
      <div className="promo__operations">
        {isExpired ? (
          <>
            <AppButton
              title={
                canRestart
                  ? "Restart the voucher as it has already expired."
                  : "Only one Auto voucher and Referral voucher can be active at a time."
              }
              onClick={onRestartClick}
              disabled={!canRestart}
              className={classNames(
                "activate-voucher-btn",
                voucherLimitExceeded && "activate-voucher-btn--disabled",
              )}
            >
              <RefreshIcon className="button__icon" />
              Restart
            </AppButton>
            <AppButton
              title="View voucher campaign."
              onClick={onRestartClick}
              variant="secondary"
              className="edit-voucher-btn"
            >
              View
            </AppButton>
          </>
        ) : (
          <>
            <AppButton
              title="Deactivate/Activate Instantly pause any campaign that is currently live."
              onClick={() => {
                onChangeActiveStatus(!isActive);
              }}
              variant={isActive ? "" : "faded"}
              className={classNames(
                "activate-voucher-btn",
                voucherLimitExceeded && "activate-voucher-btn--disabled",
              )}
            >
              {isActive ? "Deactivate" : "Activate"}
            </AppButton>
            <AppButton
              title="Edit voucher campaign."
              onClick={onEditClick}
              variant="secondary"
              leftIcon={EditDocIcon}
              disabled={!isEditable}
              className="edit-voucher-btn"
            >
              Edit
            </AppButton>
            {/* <AppButton
              title="Download your social poster"
              onClick={socialHandler}
              variant="secondary"
              leftIcon={EditDocIcon}
              disabled={!isEditable}
              className="social-poster-voucher-btn"
            >
              Social Media Poster
            </AppButton> */}
          </>
        )}
      </div>
    </div>
  );
}

export default Promo;
