import React, { useState, useEffect, useCallback } from "react";
import "./create_milestone_stamp_page.css";
import Title from "../../Components/Title/title";
import AppButton from "../../Components/AppButton/app_button";
import { Switch, Table, Select, notification, Modal } from "antd";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors/selectors";
import Label from "../../Components/Label/label";
import Explainer from "../../Components/Explainer/explainer";
import Step from "../../Components/Step/step";
import StepsNav from "../../Components/StepsNav/steps_nav";
import Uploader from "../../Components/Uploader/uploader";
import { DeleteOutlined } from "@ant-design/icons";
import axiosInstance from "../../services/axiosIntercept";
import { handleActionNotification } from "../../utils/utils";
import useGetVoucherBankVouchers from "../../Hooks/getVoucherBankVouchers";
import {
  getMilestoneCampaign,
  getMilestoneCampaignThresholdRewards,
} from "../../services/milestoneCampaignService";
import LoadingSpinner from "../../Components/LoadingSpinner/loading_spinner";
import {
  checkVoucherRestart,
  deleteReward,
} from "../../services/voucherService";
import { useNavigate } from "react-router-dom";
import useUpdatePasses from "../../Hooks/updatePasses";

interface StampReward {
  id: string;
  stampNumber: number;
  voucherId: string;
  voucherName: string;
  thresholdRewardId?: number;
}

// Add type for API error
interface ApiError {
  response?: {
    data?: {
      message?: string;
    };
  };
}

interface MilestoneRequestData {
  id?: number;
  campaignName: string;
  description: string;
  imageId?: string;
  startDate: string;
  expiryDate: string;
  isValid: boolean;
  programId: number;
  location: string | null;
  termsNConditionsURL: string;
  backgroundColor: string;
  backgroundImageId: string;
  voucherTypeId: number;
  memberSelfStamping: boolean;
  memberSelfStampingWaitHours: number;
  memberReceiptScanning: boolean;
  autoVoucher: boolean;
  referralRewardVoucher: boolean;
}

type Value = {
  id?: number | string;
  isActive: boolean;
  title: string;
  description: string;
  termsNConditionsURL: string;
  imageId: string;
};

const defaultValue: Value = {
  description: "",
  isActive: true,
  title: "",
  termsNConditionsURL: "",
  imageId: "",
};

const CreateMilestoneStamp: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [focus, setFocus] = useState(0);
  const [stampCount, setStampCount] = useState<10 | 20>(10);
  const [selectedStamps, setSelectedStamps] = useState<StampReward[]>([
    {
      id: `stamp-${stampCount}`,
      stampNumber: stampCount,
      voucherId: "",
      voucherName: "",
    },
  ]);

  const [value, setValue] = useState<Value>(defaultValue);

  // Mobile steps navigation
  const totalMobileSteps = 3;
  const [mobileStep, setMobileStep] = useState(0);
  const [allowMilestoneCampaign, setAllowMilestoneCampaign] = useState(false);

  // Get business details from Redux store
  const businessBrandColor = useSelector(selectors.getBusinessBrandColor);
  const programLocation = useSelector(selectors.getSelectedProgramLocation);
  const programId = useSelector(selectors.getSelectedLp);
  const programUuid = useSelector(selectors.getSelectedLpUuid);

  // Add tracking for initial rewards after they're loaded
  const [initialRewards, setInitialRewards] = useState<Map<number, number>>(
    new Map(),
  );

  const updatePass = useUpdatePasses();

  const refreshMilestoneCampaign = useCallback(async () => {
    try {
      setIsLoading(true);
      const [milestoneVoucher, checkRestart] = await Promise.all([
        getMilestoneCampaign(programUuid),
        checkVoucherRestart(programId),
      ]);
      // Comment out these console.logs
      // console.log("milestoneVoucher", milestoneVoucher);
      if (milestoneVoucher) {
        setValue({
          id: milestoneVoucher.id,
          title: milestoneVoucher.campaignName,
          description: milestoneVoucher.description,
          imageId: milestoneVoucher.imageId,
          isActive: milestoneVoucher.isValid,
          termsNConditionsURL: milestoneVoucher.termsNConditionsURL || "",
        });

        const thresholdRewards = await getMilestoneCampaignThresholdRewards(
          milestoneVoucher.id,
        );

        // Comment out these console.logs
        // console.log("milestoneVoucher", milestoneVoucher);
        // console.log("thresholdRewards", thresholdRewards);
        // Store initial rewards mapping
        const initialRewardsMap = new Map(
          thresholdRewards.map((row) => [
            row.stampingDigitalVoucherIssueThreshold,
            row.id,
          ]),
        );
        setInitialRewards(initialRewardsMap);

        // Comment out these console.logs
        // console.log("thresholdRewards", thresholdRewards);

        const map = thresholdRewards
          .map<StampReward>((row) => ({
            id: String(row.id),
            voucherId: String(row.bankVoucherCampaignId),
            stampNumber: row.stampingDigitalVoucherIssueThreshold,
            voucherName: "",
            thresholdRewardId: row.id,
          }))
          .reduce<Map<number, StampReward>>((map, current) => {
            if (!map.has(current.stampNumber)) {
              map.set(current.stampNumber, current);
            }
            return map;
          }, new Map());

        setSelectedStamps(Array.from(map.values()));
      } else {
        console.log("dont have milestone card");
      }

      setAllowMilestoneCampaign(checkRestart.canRestart);
    } finally {
      setIsLoading(false);
    }
  }, [programUuid]);

  useEffect(() => {
    refreshMilestoneCampaign();
  }, [refreshMilestoneCampaign]);

  const { voucherBankVouchers } = useGetVoucherBankVouchers(programId);

  // Add getVouchers hook
  const selected_lp = useSelector(selectors.getSelectedLp);

  // Add this with other selectors at the top
  const selected_lp_uuid = useSelector(selectors.getSelectedLpUuid);

  const handleStepsNavPrevClick = () => {
    setMobileStep(Math.max(mobileStep - 1, 0));
  };

  const handleStepsNavNextClick = () => {
    setMobileStep(Math.min(mobileStep + 1, totalMobileSteps - 1));
  };

  const handleStampClick = (stampNumber: number) => {
    if (stampNumber === stampCount) {
      return;
    }

    const existingStamp = selectedStamps.find(
      (stamp) => stamp.stampNumber === stampNumber,
    );

    if (existingStamp) {
      setSelectedStamps(
        selectedStamps.filter((stamp) => stamp.stampNumber !== stampNumber),
      );
    } else {
      const newReward: StampReward = {
        id: `_${stampNumber}`,
        stampNumber,
        voucherId: "",
        voucherName: "",
      };
      setSelectedStamps([...selectedStamps, newReward]);
    }
  };

  const handleVoucherSelect = (stampId: string, voucherId: string) => {
    setSelectedStamps((stamps) =>
      stamps.map((stamp) => {
        if (stamp.id === stampId) {
          const selectedVoucher = voucherBankVouchers?.find(
            (v) => v.id === voucherId,
          );
          return {
            ...stamp,
            voucherId,
            voucherName: selectedVoucher?.campaignName || "",
          };
        }
        return stamp;
      }),
    );
  };

  const handleRemoveStamp = (stampId: string) => {
    const stampToRemove = selectedStamps.find((stamp) => stamp.id === stampId);

    setSelectedStamps((stamps) =>
      stamps.filter((stamp) => stamp.id !== stampId),
    );
  };

  const handleSave = async () => {
    const rewardVoucherIds = selectedStamps
      .filter((stamp) => stamp.voucherId)
      .map((stamp) => parseInt(stamp.voucherId));

    // const hasDuplicateStampRewards =
    //   rewardVoucherIds.length !== new Set(rewardVoucherIds).size;

    // if (hasDuplicateStampRewards) {
    //   // notification.warning({
    //   //   duration: 20,
    //   //   placement: "top",
    //   //   message:
    //   //     "Milestone reward vouchers cannot contain duplicates. Please make sure each reward voucher is only used once in the milestone voucher.",
    //   // });
    //   alert(
    //     "Milestone reward vouchers cannot contain duplicates. Please make sure each reward voucher is only used once in the milestone voucher.",
    //   );
    //   return;
    // }

    try {
      const ver_btn = document.getElementById("cr-btn-ms") as HTMLButtonElement;
      if (ver_btn) {
        ver_btn.disabled = true;
      }

      // Find rewards that were removed
      const currentStampNumbers = new Set(
        selectedStamps
          .filter((stamp) => stamp.voucherId)
          .map((stamp) => stamp.stampNumber),
      );

      // Delete removed rewards
      const deletePromises = Array.from(initialRewards.entries())
        .filter(
          ([stampNumber, rewardId]) => !currentStampNumbers.has(stampNumber),
        )
        .map(([_, rewardId]) => deleteReward(rewardId));

      await Promise.all(deletePromises);

      // Convert selected stamps to thresholdAndRewards format
      const thresholdAndRewards = selectedStamps
        .filter((stamp) => stamp.voucherId)
        .map((stamp) => ({
          threshold: stamp.stampNumber,
          rewardVoucherId: parseInt(stamp.voucherId),
          ...(stamp.thresholdRewardId ? { id: stamp.thresholdRewardId } : {}),
        }));

      const requestData = {
        campaignName: value.title || "",
        description: value.description || "",
        imageId: value.imageId || null,
        startDate: "2024-04-01T00:00:01.000Z",
        expiryDate: "2100-04-01T23:59:59.000Z",
        isValid: value.isActive ?? true,
        programId: selected_lp || null,
        location: programLocation || "",
        termsNConditionsURL: value.termsNConditionsURL || "",
        backgroundColor: businessBrandColor || "",
        backgroundImageId:
          "281902f594c2b35a6bf6986a42edbe14b9f7b325847876c6dbad0622d6b6361a",
        voucherTypeId: 5,
        memberSelfStamping: false,
        memberSelfStampingWaitHours: 24,
        memberReceiptScanning: false,
        autoVoucher: false,
        referralRewardVoucher: false,
        ownerId: null,
        requrirement1: "",
        requrirement2: "",
        requirements: {},
        termsNConditions: "",
        valueDescription: "",
        eventAddress: "",
        eventCode: null,
        specialAddress: "",
        productServiceRefId: null,
        autoVoucherNumberOfVisits: stampCount,
        autoVoucherIsOneTimeUse: false,
        membershipId: null,
        arLocationId: null,
        welcomeVoucherCampaign: false,
        referralRewardVoucherLevel: null,
      };

      const requestBody = {
        data: {
          ...requestData,
          ...(value.id ? { id: value.id } : {}),
        } as MilestoneRequestData,
        thresholdAndRewards,
      };

      // Log the request body
      console.log("Milestone Create/Update Request:", requestBody);

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v1/voucher/milestone/create/update`,
        requestBody,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "content-type": "application/json",
            "program-uuid": selected_lp_uuid,
          },
        },
      );

      setValue((state) => ({
        ...state,
        id: response.data.id,
      }));

      await refreshMilestoneCampaign();

      notification.success({
        message: value.id
          ? "Milestone stamp card updated successfully"
          : "Milestone stamp card created successfully",
      });

      // Add 4 second delay then call updatePass
      setTimeout(async () => {
        await updatePass(programUuid);
      }, 4000);
    } catch (error: unknown) {
      console.error("Error creating/updating milestone stamp card:", error);
      notification.error({
        message: "Failed to save milestone stamp card",
        description:
          (error as ApiError)?.response?.data?.message ||
          "An unexpected error occurred",
      });
    } finally {
      const ver_btn = document.getElementById("cr-btn-ms") as HTMLButtonElement;
      if (ver_btn) {
        ver_btn.disabled = false;
      }
    }
  };

  const columns = [
    {
      title: "Slot",
      dataIndex: "stampNumber",
      key: "stampNumber",
    },
    {
      title: "Reward Voucher",
      dataIndex: "voucherId",
      key: "voucherId",
      render: (voucherId: string, record: StampReward) => (
        <Select
          style={{ width: "100%" }}
          value={voucherId || undefined}
          onChange={(value) => handleVoucherSelect(record.id, value)}
          placeholder="Select a voucher"
          options={voucherBankVouchers?.map((voucher) => ({
            value: String(voucher.id),
            label: voucher.campaignName,
          }))}
        ></Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: StampReward) =>
        record.stampNumber !== stampCount ? (
          <button
            onClick={() => handleRemoveStamp(record.id)}
            className="delete-button"
          >
            <DeleteOutlined />
          </button>
        ) : null,
    },
  ];

  useEffect(() => {
    const existingStamps = selectedStamps.filter(
      (stamp) => stamp.stampNumber !== 10 && stamp.stampNumber !== 20,
    );

    setSelectedStamps([
      ...existingStamps,
      {
        id: `stamp-${stampCount}`,
        stampNumber: stampCount,
        voucherId: "",
        voucherName: "",
      },
    ]);
  }, [stampCount]);

  // Sort the stamps before passing to the table
  const sortedStamps = [...selectedStamps].sort(
    (a, b) => a.stampNumber - b.stampNumber,
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="milestone-stamp container">
      <h1 className="milestone-stamp__heading">
        <Title>Milestone Stamp Card</Title>
      </h1>

      <div className="milestone-stamp__body">
        {/* Campaign Details Section */}
        <div
          className={`milestone-stamp__section${
            focus === 1 ? " milestone-stamp__section--focus" : ""
          }${
            mobileStep === 0 ? " milestone-stamp__section--mobile-current" : ""
          }`}
          onClick={() => setFocus(1)}
        >
          <div className="milestone-stamp__sub-heading">
            <Step number="1">
              <Title secondary>
                Campaign Details
                <Explainer size="20">
                  Configure your milestone stamp campaign details
                </Explainer>
              </Title>
            </Step>
          </div>

          <div className="milestone-stamp__widget">
            <Label>Campaign Name</Label>
            <input
              type="text"
              value={value.title}
              onChange={(e) =>
                setValue((state) => ({ ...state, title: e.target.value }))
              }
              placeholder="Enter campaign name"
              className="milestone-input"
            />
          </div>

          <div className="milestone-stamp__widget">
            <Label>Description</Label>
            <textarea
              value={value.description}
              onChange={(e) =>
                setValue((state) => ({ ...state, description: e.target.value }))
              }
              placeholder="Enter description (Max 2000 characters)"
              className="milestone-input"
              rows={3}
            />
          </div>

          <div className="milestone-stamp__widget">
            <Label>Link to Terms & Conditions (Optional)</Label>
            <input
              type="text"
              value={value.termsNConditionsURL}
              onChange={(e) =>
                setValue((state) => ({
                  ...state,
                  termsNConditionsURL: e.target.value,
                }))
              }
              placeholder="Enter Link to Terms and Conditions"
              className="milestone-input"
            />
          </div>

          <div className="milestone-stamp__widget">
            <Label>Campaign Image</Label>
            <Uploader
              type="image"
              message="Upload your campaign picture"
              width={315}
              height={315}
              defaultImageSrc={value.imageId}
              onUploaded={(imageId) =>
                setValue((state) => ({
                  ...state,
                  imageId,
                }))
              }
              aspectRatio={1}
            />
          </div>
        </div>

        {/* Stamp Card Settings Section */}
        <div
          className={`milestone-stamp__section${
            focus === 2 ? " milestone-stamp__section--focus" : ""
          }${
            mobileStep === 1 ? " milestone-stamp__section--mobile-current" : ""
          }`}
          onClick={() => setFocus(2)}
        >
          <div className="milestone-stamp__sub-heading">
            <Step number="2">
              <Title secondary>
                Stamp Card Settings
                <Explainer size="20">
                  Configure your stamp card pattern
                </Explainer>
              </Title>
            </Step>
          </div>

          <div className="stamp-count-toggle">
            <Label>Stamp Pattern</Label>
            <div className="stamp-count-toggle__control">
              <span className="stamp-count-toggle__text">10 stamps</span>
              <Switch
                checked={stampCount === 20}
                onChange={(checked) => setStampCount(checked ? 20 : 10)}
                style={{
                  background: stampCount === 20 ? "var(--orange)" : undefined,
                  borderColor: stampCount === 20 ? "var(--orange)" : undefined,
                }}
              />
              <span className="stamp-count-toggle__text">20 stamps</span>
            </div>
          </div>

          <div className="stamp-card-preview">
            {[...Array(stampCount)].map((_, index) => (
              <div
                key={index}
                className={`stamp-slot ${
                  selectedStamps.find(
                    (stamp) => stamp.stampNumber === index + 1,
                  )
                    ? "selected"
                    : ""
                } ${index + 1 === stampCount ? "stamp-slot--final" : ""}`}
                onClick={() => handleStampClick(index + 1)}
              >
                {index + 1}
              </div>
            ))}
          </div>
        </div>

        {/* Milestone Rewards Section */}
        <div
          className={`milestone-stamp__section${
            focus === 3 ? " milestone-stamp__section--focus" : ""
          }${
            mobileStep === 2 ? " milestone-stamp__section--mobile-current" : ""
          }`}
          onClick={() => setFocus(3)}
        >
          <div className="milestone-stamp__sub-heading">
            <Step number="3">
              <Title secondary>
                Milestone Rewards
                <Explainer size="20">
                  Configure rewards for selected stamps
                </Explainer>
              </Title>
            </Step>
          </div>

          <div className="stamp-rewards-table">
            <Table
              columns={columns}
              dataSource={sortedStamps}
              rowKey="id"
              pagination={false}
            />
          </div>

          <div className="milestone-stamp-controls">
            <Label>Status</Label>
            <Switch
              checked={value?.isActive}
              onChange={(isActive) => {
                setValue((state) => ({ ...state, isActive }));
              }}
              style={{
                background: value.isActive ? "var(--orange)" : undefined,
                borderColor: value.isActive ? "var(--orange)" : undefined,
              }}
            />
            <span>{value.isActive ? "Active" : "Inactive"}</span>
          </div>

          <div className="milestone-stamp__submit">
            <AppButton
              type="primary"
              fullWidth
              id="cr-btn-ms"
              onClick={() =>
                handleActionNotification(
                  handleSave,
                  value.id
                    ? "Would you like to update the milestone stamp card?"
                    : "Would you like to create the milestone stamp card?",
                  "Cancel",
                  "Yes",
                )
              }
            >
              {value.id ? "Update Campaign" : "Create Campaign"}
            </AppButton>
          </div>
        </div>
      </div>

      <div className="milestone-stamp__navigation">
        <StepsNav
          current={mobileStep}
          total={totalMobileSteps}
          prevClickHandler={handleStepsNavPrevClick}
          nextClickHandler={handleStepsNavNextClick}
        />
      </div>
      <Modal
        open={!allowMilestoneCampaign}
        onOk={() => {
          navigate("/dashboard/vouchers");
        }}
        closable={false}
        maskClosable={false}
        title={"Milestone Campaign"}
        okText={"Go to Manage Campaigns"}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>
          There is an active auto voucher or reward voucher campaign. Please
          disable it before creating a milestone campaign.
        </p>
      </Modal>
    </div>
  );
};

export default CreateMilestoneStamp;
