import React from "react";
import "./add.css";
import { ReactComponent as AddIcon } from "../../assets/symbols/add.svg";
import classNames from "classnames";

/**
 *
 * @param {import("react").PropsWithChildren<import("react").ButtonHTMLAttributes<HTMLButtonElement>>} props
 * @returns {JSX.Element}
 */
function Add({ children, ...restProps }) {
  return (
    <button
      type="button"
      {...restProps}
      className={classNames("add", restProps.className)}
    >
      <AddIcon class="add__icon" />
      <span className="add__label">{children}</span>
    </button>
  );
}

export default Add;
